// app/store/cart/cart.actions.ts

import { createAction, props } from '@ngrx/store';
import {Item} from '../../../core/models/item';
import {CartItem} from './cartItem';

export const addToCart = createAction(
  '[Cart] Add Item',
  props<{ item: Item }>()
);

export const loadCart = createAction(
  '[Cart] Load Items',
  props<{ items: CartItem[] }>()
);

export const removeFromCart = createAction(
  '[Cart] Remove Item',
  props<{ item: Item }>() // let's assume each product has a unique id
);

export const adjustQuantity = createAction(
  '[Cart] Adjust Item Quantity',
  props<{ itemId: string, change: number }>()
);

export const updateQuantity = createAction(
  '[Cart] Update Quantity',
  props<{ item: Item, quantity: number }>()
);

export const clearCart = createAction('[Cart] Clear');
