import {createReducer, on, Action} from '@ngrx/store';
import {addToCart, clearCart, loadCart, removeFromCart, updateQuantity} from './cart-action';
import {CartItem} from './cartItem';

export interface CartState {
  items: { [itemId: string]: CartItem };
}

export const initialCartState: CartState = {
  items: {}
};

export const cartReducer = createReducer(
  initialCartState,
  on(loadCart, (state, {items}) => {
    return {
      ...state,
      items: items.reduce(
        (itemsMap, item) => ({
          ...itemsMap,
          [item.item.id]: item
        }),
        {}
      )
    };
  }),
  on(addToCart, (state, {item}) => {
    const existingItem = state.items[item.id];
    return {
      ...state,
      items: {
        ...state.items,
        [item.id]: {
          item,
          quantity: existingItem ? existingItem.quantity + 1 : 1
        }
      }
    };
  }),
  on(removeFromCart, (state, {item}) => {
    const {[item.id]: removed, ...rest} = state.items;
    return {...state, items: rest};
  }),
  on(updateQuantity, (state, {item, quantity}) => {
    return {
      ...state,
      items: {
        ...state.items,
        [item.id]: {
          item,
          quantity
        }
      }
    };
  }),
  on(clearCart, (state) => {
    return {
      ...state,
      items: {}
    };
  }),
);

export function cartReducerFunc(state: CartState | undefined, action: Action) {
  return cartReducer(state, action);
}
